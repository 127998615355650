import IDPRedirectError from '../errors/IDPRedirectError';

const IFRAME_ELEMENT_ID = 'iframeDownload';
const IFRAME_AUTH_CALL_TIMEOUT = 15000;
// Not possible to convert to async (I think)
const createAuthIframeCall = /* istanbul ignore next: TODO: figure out how to test in the best way */ (url, rejectOnError) => {
  // Create iframe (remove old iframe if exit)
  const existingIframe = document.getElementById(IFRAME_ELEMENT_ID);
  if (existingIframe) document.body.removeChild(existingIframe);
  const iframe = document.createElement('iframe');
  iframe.id = IFRAME_ELEMENT_ID;

  // Variable storing timeout, enabling clearing
  let timeout;
  return new Promise((resolve, reject) => {
    // Create timeout, id authorize call doesn't resolve to expected redirect or error message
    timeout = setTimeout(() => reject('timeout'), IFRAME_AUTH_CALL_TIMEOUT);
    try {
      iframe.onload = () => {
        try {
          const loc = {
            ...iframe.contentWindow.location,
          };

          console.debug('ccauth', 'createAuthIframeCall', 'loc', loc.href, window.location.href);
          const finishedLoading = loc.href !== 'about:blank' && loc.href !== window.location.href;
          if (finishedLoading) {
            console.debug('ccauth', 'createAuthIframeCall', 'received redirect', loc.href);

            // stop content from loading, never relevant.
            if (iframe.contentWindow) {
              iframe.contentWindow.stop();
            }
            // TODO: Should not be necessary when A&A redirects correctly, then put the redirect to top window
            const locSearchParams = new URLSearchParams(loc.search);
            if (locSearchParams.has('error')) {
              const errorMsg = locSearchParams.get('error');
              reject(errorMsg.indexOf('not_logged_in') >= 0 ? 'not_logged_in' : new IDPRedirectError(errorMsg));
            } else {
              resolve(loc);
            }
          }
        } catch (e) {
          console.error('ccauth', 'utilCreateAuthIframeCall', 'may be an expected error, rejectOnError:', rejectOnError, e.toString());
          if (rejectOnError) reject(e);
        }
      };
      document.body.appendChild(iframe);
      iframe.src = url;
    } catch (e) {
      console.error('ccauth', 'utilCreateAuthIframeCall', e.toString());
      reject(e);
    }
  }).finally((data) => {
    clearTimeout(timeout);
    document.body.removeChild(document.getElementById('iframeDownload'));
    return data;
  });
};

export default createAuthIframeCall;
