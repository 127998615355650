import FetchTokenError from '../errors/fetchTokenError';
import { verifyState, setLastTokenScope } from '../utils';

const fetchAndStoreTokenUtil = async ({ clientId, idpKey, params, idp, endpoint }) => {
  const formBody = [`client_id=${clientId}`, ...Object.entries(params).map(([key, value]) => `${key}=${value}`)];

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    Accept: 'application/json',
  };

  if (idpKey) {
    headers.Authorization = `Basic ${idpKey}`;
  }

  const response = await fetch(`${idp}${endpoint}`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: formBody.join('&'),
  });

  if (response.ok) {
    const { expires_in, access_token, scope } = await response.json();
    const tokenData = {
      accessToken: access_token,
      expiresIn: expires_in,
      scope,
      tokenRegistered: new Date().toISOString(),
    };
    sessionStorage.setItem('sessionToken', JSON.stringify(tokenData));

    // Yet another scope in localstorage, necessary for SSO on non customer connect sites since they will not have auth store from login app, used in manageToken
    if (scope !== 'default') {
      setLastTokenScope(scope);
    }

    return tokenData;
  }

  const { error_description } = await response.json();
  console.error('ccauth', 'fetchAndStoreTokenUtil', `fetching ID token error: ${error_description}, redirect to login!`);
  throw new FetchTokenError(error_description);
};

const makeFetchAndStoreToken = (config, redirectToLogin) => (params, state, endpoint) => {
  /* Need to fix this later since it does mot work with SSO */
  if (/* state && !verifyState(state) */ false) {
    console.error(
      'ccauth',
      'fetchAndStoreToken',
      'You are most likely getting hacked, logging out!',
      state,
      sessionStorage.getItem('ccauth-state'),
      verifyState(state),
    );
    return redirectToLogin();
  }
  const { clientId, idp, clientSecret } = config;
  return fetchAndStoreTokenUtil({ clientId, idp, idpKey: clientSecret && btoa(`${clientId}:${clientSecret}`), params, endpoint });
};

const makeFetchFunctions = (config, redirectToLogin) => {
  const fetchAndStoreToken = makeFetchAndStoreToken(config, redirectToLogin);
  const fetchAndStoreTokenUsingCode = (redirectUrl, code, state) =>
    fetchAndStoreToken(
      {
        redirect_uri: redirectUrl,
        code,
        grant_type: 'authorization_code',
      },
      state,
      '/oauth/token',
    );

  const fetchAndStoreTokenUsingIdToken = (idToken, scope, state) =>
    fetchAndStoreToken(
      {
        assertion: idToken,
        scope,
        grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
      },
      state,
      '/token.oauth2',
    );

  return { fetchAndStoreTokenUsingCode, fetchAndStoreTokenUsingIdToken };
};

export default makeFetchFunctions;
