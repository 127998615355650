/* eslint-disable */
import buildConfig  from'./buildConfig' ;
import { OICD_USER_LOGIN, SUPPORT_LOGIN, TISP_LOGIN, LOCAL_IDP_USER }  from'./consts' ;
import { requiresAction, MANAGE_TOKENS, REDIRECT, SKIP }  from'./requiresAction' ;
import { isExcludedAuthPath, isLoginPath, isIFrame, getAuthMethod, setAuthMethod, removeAuthMethod, getStoredRedirectUrl, setStoredRedirectUrl, removeStoredRedirectUrl, getTokenData, verifyAccessToken, generateAndStoreTispState, getPartnerTokenData, verifyPartnerAccessToken, getLoginHint, setLoginHint }  from'./utils' ;
import makeRedirectToLogin  from'./functions/redirectToLogin' ;
import makeRedirectToAuthorize  from'./functions/redirectToAuthorize' ;
import makeRedirectToApp  from'./functions/redirectToApp' ;
import makeVerifyAndEncodeRedirectUrl  from'./functions/validateRedirectUrl' ;
import makeFetchFunctions  from'./functions/fetchFunctions' ;
import makeAzureUtils  from'./functions/azureUtils' ;
import makeIDTokenFunctions  from './functions/idTokenFunctions' ;
import makeFetchAndStorePartnerToken  from'./functions/fetchAndStorePartnerToken' ;
import makeAzureAuthorizeAndGetIDToken  from'./functions/azureAuthorize' ;
import makeLegacyAzureAuthorize  from'./functions/legacyAzureAuthorize' ;
import makeManageTokens  from'./functions/manageTokens' ;
import makeManagePartnerToken  from'./functions/managePartnerToken' ;
import makeBuildAuthorizeUrl  from'./functions/buildAuthorizeUrl' ;
import makeAuthorizeFetchAndStoreToken  from'./functions/fetchAuthorizeAndStoreToken' ;
import makeOicdAuthorize  from'./functions/oicdAuthorize' ;
import makeAuthorizeWithScope  from'./functions/authorizeWithScope' ;
import { createAuthStore, clearStatus }  from'./functions/createAuthStore' ;


// TODO: find all sessionStorage.setItem and refactor to consts
export const ccauth = () => {
  console.debug('ccauth', 'init', location.href);

  // TODO: Find all used urlParams and make part of config.
  const urlParams = new URLSearchParams(location.search);

  /**
   * Creating configuration used for login.
   */
  const config = buildConfig({
    mockPort: urlParams.get('mockPort'),
    redirectURI: urlParams.get('redirect_uri'),
    app: urlParams.get('app'),
    lambdaConfig: typeof LAMBDA_CCAUTH_CONTEXT_CONFIG !== 'undefined' && LAMBDA_CCAUTH_CONTEXT_CONFIG,
  });
  console.debug('ccauth', 'config', JSON.stringify(config));

  /**
   * Setting upp functions referencing the configuration.
   */
  const validateRedirectUrl = makeVerifyAndEncodeRedirectUrl(config);
  const redirectToLogin = makeRedirectToLogin(config.origin);
  const redirectToAuthorize = makeRedirectToAuthorize(config.origin);
  const { getAzureIdp, getAzureClientId, getAzureAuthorizeURL } = makeAzureUtils({config});
  const { fetchAndStoreTokenUsingCode, fetchAndStoreTokenUsingIdToken } = makeFetchFunctions(config, redirectToLogin);
  const {fetchIDToken, refreshIDToken}  = makeIDTokenFunctions({ getAzureIdp, getAzureClientId });

  console.debug('ccauth', 'init buildAuthorizeUrl');
  const buildAuthorizeUrl = makeBuildAuthorizeUrl({
    idp: config.idp,
    clientId: config.clientId,
    validateRedirectUrl
  });
  const authorizeFetchAndStoreToken = makeAuthorizeFetchAndStoreToken({ fetchAndStoreTokenUsingCode, buildAuthorizeUrl });

  // TODO: this cheese is just to big for one rat.
  const manageTokens = makeManageTokens({
    origin: config.origin,
    fetchAndStoreTokenUsingCode,
    fetchIDToken,
    refreshIDToken,
    fetchAndStoreTokenUsingIdToken,
    redirectToLogin,
    redirectToAuthorize,
    authorizeFetchAndStoreToken,
    validateRedirectUrl,
  });

  const fetchAndStorePartnerToken = makeFetchAndStorePartnerToken({ manageTokens, ...config });

  const managePartnerToken = makeManagePartnerToken({ manageTokens, fetchAndStorePartnerToken });

  /**
   * Setting up exported utility functions not part of the login flow but exposed on window.ccauth
   */
  const oicdAuthorize = makeOicdAuthorize({
    isTestBot: urlParams.get('isTestBot'),
    getAzureAuthorizeURL,
    validateRedirectUrl
  });
  const redirectToApp = makeRedirectToApp({ validateRedirectUrl });
  const authorizeWithScope = makeAuthorizeWithScope({
    redirectToLogin,
    redirectToApp,
    getAzureAuthorizeURL,
    validateRedirectUrl,
    fetchAndStoreTokenUsingIdToken,
    fetchIDToken,
    refreshIDToken,
    buildAuthorizeUrl
  });
  const authorizeWithIDP = (scope, origin = true) => {
    return authorizeWithScope(scope, origin ? location.origin : '');
  };

  /**
   * Global: Logging out
   */
  const logout = () => {
    console.debug('ccauth', 'Running logout');
    const authMethod = getAuthMethod();
    clearStatus();
    const redirectToLogout = () => {
      if (authMethod !== TISP_LOGIN) location.href = `${getAzureIdp()}/logout?redirect_uri=${location.origin}`;
      else location.href = `${config.idp}/logout?client_id=${config.clientId}`;
    };
    setTimeout(redirectToLogout, 50);
  };

  /**
   * Global: Logging out everywhere
   */
  const logoutEverywhere = () => {
    console.debug('ccauth', 'Running logout everywhere');
    const authMethod = getAuthMethod();
    clearStatus();
    const redirectToLogout = () => {
      if (authMethod !== TISP_LOGIN) {
        location.href = `${getAzureIdp()}/logout?redirect_uri=${location.origin}`;
      }
      else {
        location.href = `${config.idp}/logout?redirect_uri=${location.origin}`;
      }
    };
    setTimeout(redirectToLogout, 50);
  };

  /**
   * Final sanity check if the current login state requires redirect or manage token
   */
  switch (requiresAction({
    noAuth: urlParams.get('noAuth'),
    hasError: urlParams.get('error'),
    isEmergencyMode: urlParams.get('emergencyMode'),
    isNoAuthPath: isExcludedAuthPath(),
    isLogin: isLoginPath(),
    isIFrame: isIFrame()
  })){
    case REDIRECT:
      redirectToLogin();
      break;
    case MANAGE_TOKENS:
      manageTokens();
      break;
    case SKIP:
    default:
      break;
  }

  return {
    OICD_USER_LOGIN,
    SUPPORT_LOGIN,
    TISP_LOGIN,
    CLIENT_ID: config.clientId,
    TISP_LOGIN_URL: `${config.idp}/login`,
    getAuthMethod,
    setAuthMethod,
    getLoginHint,
    setLoginHint,
    removeAuthMethod,
    getStoredRedirectUrl,
    setStoredRedirectUrl,
    removeStoredRedirectUrl,
    generateAndStoreTispState,
    authorizeWithScope,
    authorizeWithIDP,
    createAuthStore,
    clearStatus,
    logout,
    logoutEverywhere,
    getTokenData,
    getPartnerTokenData,
    fetchAndStoreTokenUsingIDToken: fetchAndStoreTokenUsingIdToken,
    fetchAndStoreTokenUsingCode,
    fetchAndStorePartnerToken,
    oicdAuthorize,
    verifyAccessToken,
    verifySessionToken: verifyAccessToken,
    verifyPartnerAccessToken,
    getRedirectUrl: validateRedirectUrl,
    verifyAndEncodeRedirectUrl: validateRedirectUrl,
    authorizeFetchAndStoreToken,
    renewToken: manageTokens,
    manageTokens,
    managePartnerToken,
    renewTokenFrequency: () => 3600,
    redirectToApp,
    redirectToLogin,
  };

};

export default ccauth;
